import React from 'react';
import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';

import Layout from '../components/layout';
import ProjectsList from '../components/projects-list';
import SkillsList from '../components/skills-list';
import TheatreList from '../components/theatre-list';
import Contact from '../components/contact';
import WorkQuery from '../components/work-query';

const shortcodes = { Link, Contact, SkillsList, WorkQuery, ProjectsList, TheatreList };

export const ResumePageTemplate = ({ body }) => (
  <section className="section resume">
    <MDXProvider components={shortcodes}>
      <MDXRenderer>{body}</MDXRenderer>
    </MDXProvider>
  </section>
);

const ResumePage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || ''}
    slug={page.frontmatter.slug || false}
  >
    <ResumePageTemplate {...page} {...page.frontmatter} />
  </Layout>
);

export default ResumePage;

export const pageQuery = graphql`
  query ResumePage($id: String!) {
    page: mdx(id: { eq: $id }) {
      body
      frontmatter {
        title
        slug
        template
        featuredImage
        meta {
          title
          description
        }
      }
      fields {
        contentType
      }
    }
  }
`;
