import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import SkillLogo from './skill-logo';

const reduceToGroups = query => (groups, skill) => {
  const hasQuery = Object.keys(query).length;
  const includeGroup = !hasQuery || Object.keys(query).includes(skill.group);

  if (!includeGroup) return groups;

  if (!groups.has(skill.group)) {
    groups.set(skill.group, []);
  }
  if (!hasQuery || query[skill.group].includes(skill.name)) {
    groups.get(skill.group).push(skill);
  }
  return groups;
};

const SkillsList = ({ query = {} }) => {
  const {
    allSkillsYaml: { nodes: entries },
  } = useStaticQuery(graphql`
    query SkillsQuery {
      allSkillsYaml {
        nodes {
          name
          group
          logoPos
          proficiency
        }
      }
    }
  `);

  const groups = entries.reduce(reduceToGroups(query), new Map());
  const { size } = query;

  return (
    <section className="skills">
      {[...groups.entries()].map(([name, group]) => (
        <div key={name} className="skill-group">
          <h3>{name}</h3>
          <div className="skill-group-container">
            {group.map((entry, i) => (
              <div key={i} className="skill-entry">
                <SkillLogo {...entry} size={size || 4} />
                <h4>{entry.name}</h4>
              </div>
            ))}
          </div>
        </div>
      ))}
    </section>
  );
};

export default SkillsList;
