import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import WorkDetail from './work-detail';

const findByQuery = query => entry => {
  let matched = false;
  const work = entry.frontmatter;
  for (const key in query) {
    if (work.hasOwnProperty(key)) {
      matched = work[key] === query[key];
    }
  }
  return matched;
};

/**
 * @param {Map<String, React.ReactChild[]>} sections
 * @param {React.ReactChild} child
 */
const reduceContentToSections = (sections, child) => {
  const { originalType, children } = child.props;
  // separate sections by headings
  if (/^h\d$/.test(originalType) && typeof children === 'string') {
    sections.set(children, []);
  }
  sections.get(Array.from(sections.keys()).pop()).push(child);
  return sections;
};

const WorkQuery = ({ query, children }) => {
  const {
    allMdx: { nodes: entries },
  } = useStaticQuery(graphql`
    {
      allMdx(
        filter: {
          fields: { contentType: { eq: "work" }, name: { ne: "index" } }
        }
      ) {
        nodes {
          frontmatter {
            ...Work
          }
          body
        }
      }
    }
  `);

  const entry = entries.find(findByQuery(query));
  const sections = new Map();

  return (
    entry && (
      <WorkDetail {...entry.frontmatter}>
        {children}
        <MDXProvider
          components={{
            wrapper: ({ children, props }) => {
              if (!sections.size) {
                children.reduce(reduceContentToSections, sections);
              }
              // filter for sections included in query
              return [...sections]
                .filter(
                  ([name, _children]) =>
                    !query.sections ||
                    Object.keys(query.sections).includes(name)
                )
                .map(([name, children], i) => {
                  return (
                    <div key={i} className="work-section">
                      {children.map(child => {
                        if (query.sections && query.sections[name]) {
                          const { limit } = query.sections[name];
                          if (child.props.originalType === 'ul' && limit) {
                            return child.props.children.slice(0, limit);
                          }
                        }
                        return child;
                      })}
                    </div>
                  );
                });
            },
          }}
        >
          <MDXRenderer>{entry.body}</MDXRenderer>
        </MDXProvider>
      </WorkDetail>
    )
  );
};

export default WorkQuery;
