import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Content from './content';
import SkillLogo from './skill-logo';
import Icon from './icon';

const ProjectsList = ({ query }) => {
  const {
    allProjectsYaml: { nodes: entries },
  } = useStaticQuery(graphql`
    query ProjectsQuery {
      allProjectsYaml(sort: { order: DESC, fields: dates }) {
        nodes {
          ...Project
        }
      }
    }
  `);
  return entries
    .filter(entry => !query || query.projects.includes(entry.title))
    .map((entry, i) => (
      <div key={i} className="project-entry">
        <small>{entry.dates}</small>
        <h4>
          {entry.repo && (
            <a
              className="link-container"
              href={entry.repo}
              rel="noreferrer"
              target="_blank"
              title={`Go to ${entry.repo}`}
            >
              <Icon icon={Icon.GITHUB} size="small" />
            </a>
          )}
          <span>{entry.title}</span>
          {entry.url && (
            <a
              className="link-container"
              href={entry.url}
              rel="noreferrer"
              target="_blank"
              title={`Go to ${entry.url}`}
            >
              <Icon icon={Icon.NEW_WINDOW} size="small" />
            </a>
          )}
        </h4>
        <div className="project-skills">
          {entry.skills.length &&
            entry.skills.map((skill, i) => (
              <SkillLogo key={i} name={skill} toolTip />
            ))}
        </div>
        <div className="project-description">
          <Content source={entry.description} />
        </div>
      </div>
    ));
};

export default ProjectsList;

export const query = graphql`
  fragment Project on ProjectsYaml {
    title
    dates
    description
    repo
    url
    skills
  }
`;
