import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Content from './content';

const TheatreList = ({ query }) => {
  const {
    allTheatreYaml: { nodes: entries },
  } = useStaticQuery(graphql`
    query TheatreQuery {
      allTheatreYaml(sort: { order: DESC, fields: dates }) {
        nodes {
          ...Theatre
        }
      }
    }
  `);
  return entries
    .filter(entry => !query || query.titles.includes(entry.title))
    .map((entry, i) => (
      <div key={i} className="theatre-entry">
        <small className="theatre-dates">{entry.dates}</small>
        <strong className="theatre-organization">{entry.organization}</strong>
        <h3>
          {entry.url ? (
            <a
              href={entry.url}
              rel="noreferrer"
              target="_blank"
              title={`Go to ${entry.url}`}
            >
              {entry.title}
            </a>
          ) : (
            <span>{entry.title}</span>
          )}
          <span className="theatre-role">{entry.role}</span>
        </h3>
        <div className="theatre-description">
          <Content source={entry.description} />
        </div>
      </div>
    ));
};

export default TheatreList;

export const query = graphql`
  fragment Theatre on TheatreYaml {
    title
    role
    organization
    dates
    description
    url
  }
`;
