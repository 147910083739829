import React from 'react';
import Img from 'gatsby-image';
import SkillLogo from './skill-logo';
import { graphql } from 'gatsby';

const WorkDetail = ({
  employer,
  role,
  dates,
  location,
  image,
  skills,
  children,
}) => (
  <article className="detail-item">
    <header className="detail-header">
      <div className="detail-image">
        {image ? (
          image.childImageSharp ? (
            <Img {...image.childImageSharp} />
          ) : (
            <img src={image.publicURL} alt={employer} />
          )
        ) : null}
      </div>
      <div className="detail-heading">
        <em>{dates}</em>
        <h2 className="work-role">{role}</h2>
        <h3>
          {employer} <small>{location}</small>
        </h3>
      </div>
    </header>
    <div className="detail-content">
      <div className="detail-skills">
        {skills
          ? skills.map((skill, i) => <SkillLogo key={i} name={skill} toolTip />)
          : null}
      </div>
    </div>
    {children}
  </article>
);

export const query = graphql`
  fragment Work on MdxFrontmatter {
    employer
    role
    dates
    location
    url
    skills
    image {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
      publicURL
    }
  }
`;

export default WorkDetail;
